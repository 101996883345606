body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MainContent h1 {
  font-size: 5.5rem;
  border: 7px solid;
  border-radius: 25px;
  /*color: #9d42ad;*/
}

.MainContent h2 {
  font-size: 3.3rem;
  /*color: #4847a0;*/
  border: 2px dotted;
}

.MainContent h3 {
  font-size: 2.5rem;
  text-align: left;
  text-decoration: underline dotted
}

.MainContent h4 {
  font-size: 1.7rem;
  text-align: left;
}

p {
  font-size: 1.4rem;
  text-align: left;
}
